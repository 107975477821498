<template>
  <div class="bac">
    <div>
      <el-card shadow="always" style="width: 1500px">
        <el-button type="primary" @click="this.addAdvantageDialog = true">添加项目</el-button>
        <el-table
            :data="info"
            highlight-current-row
            max-height="900"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column label="项目名称" prop="projectName"></el-table-column>
          <el-table-column label="项目职务" prop="duty"></el-table-column>

          <el-table-column label="操作" width="500">
            <template #default="scope">
              <el-button disabled>查看</el-button>
              <el-button type="danger" @click="Delete(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>

  <el-dialog v-model="addAdvantageDialog" title="添加项目经验" width="50%">
    <el-form :model="addAdvantageForm" style="width: 50%;">
      <el-form-item label="项目名称" label-width="100px">
        <el-input v-model="addAdvantageForm.projectName" autocomplete="off" />
      </el-form-item>

      <el-form-item label="开始时间" label-width="100px">
        <el-date-picker
            v-model="addAdvantageForm.start"
            type="date"
            format="YYYY/MM/DD"
            value-format="x"
            placeholder="开始日期和时间"
        />
      </el-form-item>

      <el-form-item label="结束时间" label-width="100px">
        <el-date-picker
            v-model="addAdvantageForm.end"
            type="date"
            format="YYYY/MM/DD"
            value-format="x"
            placeholder="结束日期"
        />
      </el-form-item>

      <el-form-item label="公司名称" label-width="100px">
        <el-input v-model="addAdvantageForm.companyName" autocomplete="off" />
      </el-form-item>

      <el-form-item label="项目职务" label-width="100px">
        <el-input v-model="addAdvantageForm.duty" autocomplete="off" />
      </el-form-item>

      <el-form-item label="项目描述" label-width="100px">
        <el-input v-model="addAdvantageForm.info" autocomplete="off" />
      </el-form-item>

      <el-form-item label="项目负责" label-width="100px">
        <el-input v-model="addAdvantageForm.performance" autocomplete="off" />
      </el-form-item>

      <el-form-item label="项目业绩" label-width="100px">
        <el-input v-model="addAdvantageForm.performance" autocomplete="off" />
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addAdvantageDialog = false">取消</el-button>
        <el-button type="primary" @click="addAdvantage">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "AllCertificate",
  data(){
    return{
      subform:{
        sid:'',
        currentPage:1,
        pageSize: 20,
      },

      info:[''],
      total: 0,

      addAdvantageDialog: false,

      deleteFrom:{
        aid:'',
      },

      addAdvantageForm:{
        projectName:'',
        start:'',
        end:'',
        companyName:'',
        duty:'',
        info:'',
        responsibility:'',
        performance:'',
        sid:'',
      },
    }
  },
  mounted() {
    this.subform.sid = this.$route.query.sid;
    this.addAdvantageForm.sid = this.$route.query.sid;
    this.getInf()
  },
  methods:{
    getInf() {
      this.$http({
        method: 'post',
        url: '/supplyChain/getAllAdvantagesForSupplier',
        data: this.subform,
      }).then(res => {
        this.info = res.data.data;
        this.total = res.data.count;
        this.subform.currentPage = res.data.currentPage;
      })
    },

    Delete(index){
      this.deleteFrom.aid = index;
      this.$http({
        method: 'post',
        url: '/admin/deleteAdvantage',
        data: this.deleteFrom,
      }).then(res => {
        if (res.data.code === 1){
          this.$notify({
            title: '删除成功',
            message: res.data.message,
            type: 'success'
          });
          this.getInf();
        }
      })
    },

    addAdvantage(){
      this.$http({
        method: 'post',
        url: '/admin/addAdvantage',
        data: this.addAdvantageForm,
      }).then(res => {
        if (res.data.code === 1){
          this.$notify({
            title: '添加成功',
            message: res.data.message,
            type: 'success'
          });
          this.addAdvantageDialog = false;
          this.getInf();
        }
      })
    }
  }
}
</script>

<style scoped>
.bac{
  height: 1194px;
}
</style>